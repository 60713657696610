import { useMemo } from 'react';
import { SponsorsModuleOrderTypes } from '../types';
import {
  DiscoveryBrandSponsorOrganisation,
  HubSponsorsDiscoveryBlock,
} from '../../../../../../../store/features/discovery';

export function sortSponsorBlocks(
  sponsors: DiscoveryBrandSponsorOrganisation[],
) {
  return sponsors.sort((a, b) => a.name.localeCompare(b.name));
}

export function useSponsorBlocks(
  sponsorsFromDiscovery: HubSponsorsDiscoveryBlock[],
  sponsorsOrder: SponsorsModuleOrderTypes,
) {
  return useMemo(() => {
    const sponsorBlocksHaveSponsors = sponsorsFromDiscovery.filter(
      (spexBlock) => spexBlock.spexs.length > 0,
    );

    if (sponsorsOrder === SponsorsModuleOrderTypes.ALPHABETICAL) {
      return sponsorBlocksHaveSponsors.map((sponsorBlock) => ({
        ...sponsorBlock,
        spexs: sortSponsorBlocks([...sponsorBlock.spexs]),
      }));
    }

    return sponsorBlocksHaveSponsors;
  }, [sponsorsFromDiscovery, sponsorsOrder]);
}
